
import { ref, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { nickName, updateNickname } from '@/pages/setting/setting'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Button from '@/components/Button.vue'

export default defineComponent(
  {
    name: 'TheNickName',
    components: { Button, PageWithHeader },
    setup () {
      const router = useRouter()
      const newName = ref('')

      const update = () => {
        updateNickname(newName.value).then(() => {
          router.back()
        })
      }

      return { nickName, newName, update }
    },
  },
)
